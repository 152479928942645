.details {
  border-radius: 8px;
  background-color: #ffffff;
  margin: 15px;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  border: solid 1.3px #ffffff;
  padding: 5px 20px 20px;
  cursor: pointer;
}

.details:hover {
  border: solid 1.3px #07C0CA;
}

.exp {
  margin-top: -5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #52576F;
}

.nav {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.mint {
  color: #07C0CA;
}

.grey {
  color: #D6D8E1;
}

.black {
  color: #52576F;
}

.amount {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
}

.delim {
  font-size: 12px;
}

.status {
  padding-top: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}

.open_icon {
  padding-top: 20px;
  font-size: 16px;
}