@mixin for-phone {
  @media (max-width: 599px) { @content; }
}

@mixin _list {
  ul {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 24px;
    @include for-phone {
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: 16px;
    }
    li {
      list-style-type: "\2022\20\20";
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 140% !important;
      color: #31364C !important;
      margin-bottom: 10px !important;
      @include for-phone {
        font-size: 12px !important;
        margin-bottom: 5px !important;
      }
    }
  }
}

.document {
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #31364C;
  @include for-phone {
    font-size: 12px;
  }
  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #31364C;
    margin-bottom: 20px;
    @include for-phone {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  h2 {
    padding-top: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #31364C;
    margin-bottom: 10px;
    @include for-phone {
      font-size: 12px;
      padding-top: 0;
    }
  }

  h3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #31364C;
    margin-top: 10px;
    margin-bottom: 10px;
    @include for-phone {
      font-size: 12px;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      text-align: right;
      @include for-phone {
        font-size: 12px;
      }
    }
  }

  div {
    margin-bottom: 20px;
    font-size: 14px;
    @include _list;
    @include for-phone {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  table {
    width: 100%;
    margin-bottom: 20px;
    border-top-style: solid;
    border-top-width: 1pt;
    @include for-phone {
      margin-bottom: 15px;
    }
    tr {
      border-left-style: solid;
      border-left-width: 1pt;
      th {
        vertical-align: top;
        padding: 15px;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        border-bottom-style: solid;
        border-bottom-width: 1pt;
        border-right-style: solid;
        border-right-width: 1pt;
        @include for-phone {
          font-size: 12px;
          padding: 8px;
        }
      }
      td {
        vertical-align: top;
        padding: 15px;
        border-bottom-style: solid;
        border-bottom-width: 1pt;
        border-right-style: solid;
        border-right-width: 1pt;
        @include for-phone {
          font-size: 12px;
          padding: 8px;
        }
        h4 {
          margin-top: 10px !important;
          font-weight: 700 !important;
          font-size: 14px !important;
          text-align: left !important;
          margin-bottom: 5px !important;
          @include for-phone {
            margin-top: 5px !important;
            font-size: 12px !important;
            margin-bottom: 0 !important;
          }
        }
        div {
          span {
            font-weight: 700;
          }
        }
        @include _list;
      }
    }
  }

  .modified {
    margin-bottom: 15px;
  }

  .saveCopy {
    margin-top: -10px;
    margin-bottom: 15px;
    @include for-phone {
      margin-top: -3px;
    }
  }

  .p1 {
    margin-bottom: 40px;
    @include for-phone {
      margin-bottom: 30px;
    }
  }

  .p2 {
    margin-bottom: 40px;
    @include for-phone {
      margin-bottom: 30px;
      padding-right: 10px;
    }
  }

  .lightTable {
    border-top-color: #ACE9EB;
    tr {
      border-left-color: #ACE9EB;
      th {
        background: #07C0CA;
        color: #FFFFFF;
        border-bottom-color: #ACE9EB;
        border-right-color: #ACE9EB;
      }
      td {
        border-bottom-color: #ACE9EB;
        border-right-color: #ACE9EB;
      }
    }
  }

  .darkTable {
    border-top-color: #D6D8E1;
    tr {
      border-left-color: #D6D8E1;
      th {
        background: #0A304C;
        color: #FFFFFF;
        border-bottom-color: #D6D8E1;
        border-right-color: #D6D8E1;
      }
      td {
        border-bottom-color: #D6D8E1;
        border-right-color: #D6D8E1;
      }
    }
  }

  .firstTableHeader {
    height: 50px;
    th:first-of-type {
      width: 30%;
    }
  }

  .secondTableRow {
    td {
      div:first-of-type {
        font-weight: 700;
      }
    }
  }

  .thirdTableRow {
    th {
      width: 35%;
    }
    td {
      div:last-of-type {
        margin-top: 10px;
      }
    }
  }

  .forthTableRow {
    td:first-of-type {
      font-weight: 700;
    }
  }

}
